import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Inbox2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M3.125 10.625H6.72517C7.01771 12.1672 8.37267 13.3333 10 13.3333C11.6273 13.3333 12.9823 12.1672 13.2748 10.625L16.875 10.625M16.0417 16.875H3.95834C3.4981 16.875 3.125 16.5019 3.125 16.0417L3.12504 3.95834C3.12504 3.4981 3.49814 3.125 3.95837 3.125L16.0417 3.125C16.5019 3.125 16.875 3.4981 16.875 3.95833V16.0417C16.875 16.5019 16.5019 16.875 16.0417 16.875Z"
        stroke={stroke}
        stroke-opacity="0.7"
        stroke-width="1.25"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </Icon>
  );
}
