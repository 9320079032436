import React from "react";
import Icon, { IconProps } from "./Icon";

export default function TaxFillingService({
  viewBox = "0 0 48 62",
  width = 48,
  height = 62,
  fill = "none",
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path
        fill="#50FAAB"
        d="M9.96 43.895a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zM9.96 55.628a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zM9.96 32.163a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zM38.079 43.895a3.32 3.32 0 100-6.642 3.32 3.32 0 000 6.642zM24.019 32.163a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zM24.019 43.895a3.32 3.32 0 100-6.641 3.32 3.32 0 000 6.641zM24.019 55.628a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zM38.079 55.628a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zM38.079 32.163a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64z"
      ></path>
      <path
        fill="#50FAAB"
        fillOpacity="0.4"
        d="M0 57.01A4.992 4.992 0 004.963 62h37.815c2.737 0 4.925-2.253 4.925-4.99V20.444H0V57.01zm38.079-32.823a4.656 4.656 0 110 9.312 4.656 4.656 0 010-9.312zm0 11.732a4.657 4.657 0 11-4.656 4.656 4.662 4.662 0 014.656-4.656zm0 11.732a4.657 4.657 0 11-4.656 4.656 4.661 4.661 0 014.656-4.655zm-14.06-23.464a4.656 4.656 0 110 9.311 4.656 4.656 0 010-9.311zm0 11.732a4.657 4.657 0 11-4.656 4.656 4.661 4.661 0 014.656-4.656zm0 11.732a4.656 4.656 0 11-4.656 4.656 4.661 4.661 0 014.656-4.655zM9.959 24.187a4.656 4.656 0 110 9.312 4.656 4.656 0 010-9.312zm0 11.732a4.656 4.656 0 11-4.656 4.656 4.661 4.661 0 014.656-4.656zm0 11.732a4.656 4.656 0 11-4.656 4.656 4.661 4.661 0 014.656-4.655zm-8.088-24.79a.668.668 0 111.336 0v.575a.668.668 0 11-1.336 0v-.574zm0 3.381a.668.668 0 111.336 0v9.62a.668.668 0 11-1.336 0v-9.62zM42.778 0H4.963A4.992 4.992 0 000 4.99v14.118h47.703V4.99c0-2.737-2.188-4.99-4.925-4.99zm.782 15.634a.668.668 0 01-.668.668H5.078a.668.668 0 01-.669-.668V4.142a.668.668 0 01.669-.668h37.814a.668.668 0 01.668.668v11.492z"
      ></path>
      <path
        fill="#50FAAB"
        d="M5.746 14.966h36.478V4.81H5.746v10.155zM26.59 7.45a.57.57 0 01-.1-.312c0-.423.573-.745 1.016-.745a.49.49 0 01.473.261l1.339 2.336 1.339-2.336a.49.49 0 01.473-.261c.443 0 1.017.322 1.017.744a.57.57 0 01-.101.312l-1.671 2.679 1.741 2.687a.546.546 0 01.09.282c0 .433-.613.775-1.046.775a.413.413 0 01-.372-.16l-1.47-2.497-1.47 2.497a.423.423 0 01-.372.16c-.433 0-1.047-.342-1.047-.775a.546.546 0 01.09-.282l1.742-2.687L26.59 7.45zm-3.211-1.057c.463 0 .916.172 1.027.525l1.882 6.14c.01.037.017.075.02.113 0 .373-.574.649-1.007.649-.251 0-.453-.073-.513-.284l-.342-1.243h-2.124l-.342 1.243c-.06.211-.262.276-.514.276-.433 0-1.006-.272-1.006-.644a.515.515 0 01.02-.111l1.872-6.14c.11-.353.564-.524 1.027-.524zm-7.228.02H20.5c.332 0 .473.393.473.726 0 .382-.171.745-.473.745h-1.392v5.444c0 .323-.409.484-.802.484-.392 0-.801-.16-.801-.483V7.883H16.15c-.302 0-.473-.363-.473-.745 0-.333.141-.725.473-.725z"
      ></path>
      <path fill="#50FAAB" d="M23.378 8.393l-.734 2.697h1.47l-.736-2.697z"></path>
    </Icon>
  );
}
