import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ChartRising({
  viewBox = "0 0 17 13",
  width = 17,
  height = 13,
  color = "#D3A5F7",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} {...iconProps} fill="transparent">
      <g>
        <g>
          <path
            fill={color}
            stroke={stroke}
            strokeWidth="0.25"
            d="M9.866 3.222l2.609 5h0a.375.375 0 00.31.2h.001a.38.38 0 00.33-.161V8.26l3.626-5.3h0a.374.374 0 00-.098-.522l-.195-.134v.001l-.218-.148s0 0 0 0a.375.375 0 00-.521.097m-5.844.968L.915 11.758l2.804-5.61s0 0 0 0a.375.375 0 01.647-.04m5.5-2.886l-3.403 7.259h0a.375.375 0 01-.652.048s0 0 0 0L4.163 8.057l-2.13 4.26h0a.375.375 0 01-.217.188m8.05-9.283L.915 11.758a.375.375 0 00.167.503h0l.447.223.056-.112-.055.112h0a.373.373 0 00.286.02M15.71 2.255h0l.103.07-.103-.07zm0 0l-2.802 4.097m0 0l-2.752-5.273m2.752 5.273l-.08.117.145.009-.065-.126zm-2.752-5.273a.367.367 0 00-.342-.203m.342.203h.001l-.112.056.111-.057h0zM9.814.875a.375.375 0 00-.33.216h0L5.991 8.544M9.814.875h.002L9.818 1 9.813.875h.001zm-3.823 7.67L4.366 6.108m1.625 2.435l-.062.133.143-.011-.08-.122zM4.366 6.11h0l-.104.069.104-.07zm-2.55 6.396s0 0 0 0l-.04-.12.04.12s0 0 0 0z"
          ></path>
        </g>
      </g>
    </Icon>
  );
}
