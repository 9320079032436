import React from "react";
import Icon, { IconProps } from "./Icon";

export default function NavChecklist({
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  active,
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} width={width} height={height} {...iconProps} fill="none" stroke="none">
      <path
        d="M20 12H16.8L14.4 17L9.6 7L7.2 12H4"
        stroke={active ? "#50FAAB" : "#595861"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="4"
        stroke={active ? "#95949B" : "#B3B2B8"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
