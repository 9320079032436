import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

const Vendors = ({ className, size = 19, viewBox = "0 0 18 19" }: IconProps) => (
  <Icon className={className} viewBox={viewBox} size={size}>
    <path
      fill={Colors.BaseGrey}
      fillRule="evenodd"
      d="M17.23 8.557a.385.385 0 00-.026.016l-.002.001-.002.001a1.523 1.523 0 01-1.538-.02 1.568 1.568 0 01-.559-.58 1.61 1.61 0 01-.204-.785v-.631H18v.631c0 .276-.07.547-.205.787-.134.24-.327.439-.56.578l-.002.001-.002.001zM7.084 1.967l-.24 3.953H3.789l.72-3.953h2.575zm6.405 0l.722 3.953h-3.055l-.24-3.953h2.573zm-3.2 0l.24 3.953H7.47l.241-3.953h2.58zM3.725 6.559h3.1v.631a1.6 1.6 0 01-.454 1.12c-.29.297-.685.464-1.096.464-.411 0-.805-.167-1.096-.464a1.6 1.6 0 01-.454-1.12V6.56zm3.725 0h3.1v.631a1.613 1.613 0 01-.448 1.132A1.548 1.548 0 019 8.792a1.522 1.522 0 01-1.102-.47A1.587 1.587 0 017.45 7.19V6.56zm3.725 0h3.1v.632a1.6 1.6 0 01-.455 1.12c-.29.297-.684.463-1.095.463-.412 0-.806-.166-1.096-.463a1.6 1.6 0 01-.454-1.12V6.56zm6.71-.64L16.64 1.968h-2.515l.72 3.953h3.039zM1.89 0h14.22a.436.436 0 01.314.133.454.454 0 01.13.321v.874H1.445V.454a.46.46 0 01.13-.32A.44.44 0 011.89 0zm-.53 1.967L.117 5.92h3.037l.72-3.953H1.362zM0 6.559h3.1v.631c0 .276-.07.547-.204.786-.134.24-.327.439-.559.579a1.525 1.525 0 01-1.537.02l-.003-.001-.003-.002a.223.223 0 00-.024-.014l-.003-.002H.765a1.568 1.568 0 01-.56-.58A1.61 1.61 0 010 7.19V6.56zm14.652 1.88l-.065-.096-.064.096c-.2.3-.468.545-.781.715a2.137 2.137 0 01-2.035 0 2.19 2.19 0 01-.78-.715l-.065-.096-.064.096c-.2.3-.468.545-.78.715a2.136 2.136 0 01-2.035 0 2.19 2.19 0 01-.781-.715l-.064-.096-.065.096c-.2.3-.467.545-.78.715a2.137 2.137 0 01-2.036 0 2.19 2.19 0 01-.78-.715l-.065-.096-.064.097a2.18 2.18 0 01-1.017.824 2.128 2.128 0 01-1.295.085L.94 9.325v6.811h4.623v-5.269c0-.084.033-.166.092-.225a.31.31 0 01.22-.094h6.248a.306.306 0 01.22.094.32.32 0 01.092.225v5.27h4.625V9.324l-.097.024c-.43.107-.882.078-1.295-.085a2.18 2.18 0 01-1.016-.825zM0 17.54c0-.203.079-.398.22-.54a.74.74 0 01.528-.225h16.504a.735.735 0 01.529.224.766.766 0 01.219.54V19H0v-1.462z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default Vendors;
