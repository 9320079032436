import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ThumbUpFilled({
  color = "currentColor",
  viewBox = "0 0 14 14",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        d="M5.5727 2.13412L3.72249 5.83341V12.8334H10.3826L10.4862 12.8304C11.2756 12.7839 11.9418 12.2105 12.0986 11.4266L12.7986 7.92662L12.8158 7.82521C12.9438 6.91397 12.3396 6.05017 11.4258 5.8674C11.3128 5.8448 11.1978 5.83341 11.0826 5.83341H8.75024V2.62508L8.74714 2.5292C8.69777 1.76848 8.06511 1.16675 7.29191 1.16675H7.13795L7.02162 1.1706C6.40488 1.21156 5.8517 1.57613 5.5727 2.13412Z"
        fill={color}
      />
      <path
        d="M1.16406 7.58633C1.16406 6.61983 1.94756 5.83633 2.91406 5.83633H3.08906V12.8363H2.91406C1.94756 12.8363 1.16406 12.0528 1.16406 11.0863V7.58633Z"
        fill={color}
      />
    </Icon>
  );
}
