import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Briefcase({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={width} {...iconProps} fill="none" viewBox={viewBox}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox={viewBox}
      >
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#fff"
                  fillOpacity="0.7"
                  d="M15 7.5a4.5 4.5 0 10-9 0h1.507a2.993 2.993 0 015.986 0H15z"
                ></path>
                <path
                  stroke="#fff"
                  strokeOpacity="0.7"
                  strokeWidth="1.5"
                  d="M4.25 8.25H16.75V16.75H4.25z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
}
