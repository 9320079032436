import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Resync({ viewBox = "0 0 18 18", ...iconProps }: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        fill={Colors.BaseGrey}
        d="M15.2344 2.76562C15.3164 2.68359 15.4258 2.65625 15.5625 2.71094C15.6992 2.76562 15.7812 2.875 15.7812 3.01172V6.92188C15.7812 7.03125 15.7266 7.11328 15.6719 7.16797C15.6172 7.22266 15.5352 7.25 15.4531 7.25H11.543C11.3789 7.25 11.2969 7.19531 11.2422 7.05859C11.1875 6.92188 11.1875 6.8125 11.2969 6.70312L12.8008 5.19922C12.2812 4.70703 11.707 4.32422 11.0508 4.05078C10.3945 3.77734 9.71094 3.64062 9 3.64062C8.01562 3.64062 7.14062 3.88672 6.32031 4.37891C5.5 4.87109 4.84375 5.5 4.35156 6.32031C3.85938 7.14062 3.64062 8.04297 3.64062 9C3.64062 9.98438 3.85938 10.8594 4.35156 11.6797C4.84375 12.5 5.5 13.1562 6.32031 13.6484C7.14062 14.1406 8.01562 14.3594 9 14.3594C9.65625 14.3594 10.3125 14.25 10.9141 14.0039C11.5156 13.7852 12.0625 13.4297 12.5547 12.9922C12.6094 12.9375 12.6914 12.9375 12.8008 12.9375C12.8828 12.9375 12.9648 12.9648 13.0195 13.0195L13.5664 13.5664C13.6211 13.6211 13.6484 13.7031 13.6484 13.8125C13.6484 13.9219 13.5938 13.9766 13.5391 14.0312C12.9375 14.5781 12.2266 15.0156 11.4609 15.3164C10.668 15.6445 9.84766 15.7812 9 15.7812C7.76953 15.7812 6.64844 15.4805 5.60938 14.8789C4.57031 14.2773 3.72266 13.457 3.12109 12.418C2.51953 11.3789 2.21875 10.2578 2.21875 9.02734C2.21875 7.79688 2.51953 6.64844 3.12109 5.60938C3.72266 4.57031 4.57031 3.75 5.60938 3.14844C6.64844 2.54688 7.76953 2.21875 9 2.21875C9.90234 2.21875 10.7773 2.41016 11.5977 2.73828C12.418 3.09375 13.1562 3.58594 13.7852 4.21484L15.2344 2.76562Z"
      />
    </Icon>
  );
}
