import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ThumbDownFilled({
  color = "currentColor",
  viewBox = "0 0 14 14",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        d="M8.4267 11.8658L10.2769 8.16649V1.16649H3.61684L3.5132 1.16953C2.72376 1.21596 2.05761 1.78938 1.90083 2.57329L1.20083 6.07329L1.18357 6.1747C1.05561 7.08594 1.65976 7.94973 2.57364 8.13251C2.68664 8.15511 2.8016 8.16649 2.91684 8.16649H5.24916V11.3748L5.25226 11.4707C5.30164 12.2314 5.93429 12.8332 6.70749 12.8332H6.86145L6.97778 12.8293C7.59452 12.7883 8.1477 12.4238 8.4267 11.8658Z"
        fill={color}
      />
      <path
        d="M10.9113 1.16357H11.0863C12.0528 1.16357 12.8363 1.94708 12.8363 2.91357V6.41357C12.8363 7.38007 12.0528 8.16357 11.0863 8.16357H10.9113V1.16357Z"
        fill={color}
      />
    </Icon>
  );
}
