import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Dashboard2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M3.125 4.45833C3.125 3.99162 3.125 3.75827 3.21583 3.58001C3.29572 3.42321 3.42321 3.29572 3.58001 3.21583C3.75827 3.125 3.99162 3.125 4.45833 3.125H8.54167V6.475C8.54167 6.61501 8.54167 6.68502 8.51442 6.7385C8.49045 6.78554 8.4522 6.82378 8.40516 6.84775C8.35169 6.875 8.28168 6.875 8.14167 6.875H3.125V4.45833Z"
        stroke={stroke}
        stroke-opacity="0.7"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4583 13.525C11.4583 13.385 11.4583 13.315 11.4856 13.2615C11.5095 13.2145 11.5478 13.1762 11.5948 13.1522C11.6483 13.125 11.7183 13.125 11.8583 13.125H16.875V15.5417C16.875 16.0084 16.875 16.2417 16.7842 16.42C16.7043 16.5768 16.5768 16.7043 16.42 16.7842C16.2417 16.875 16.0084 16.875 15.5417 16.875H11.4583V13.525Z"
        stroke={stroke}
        stroke-opacity="0.7"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.125 9.79167H8.14167C8.28168 9.79167 8.35169 9.79167 8.40516 9.81891C8.4522 9.84288 8.49045 9.88113 8.51442 9.92817C8.54167 9.98165 8.54167 10.0517 8.54167 10.1917V16.875H4.45833C3.99162 16.875 3.75827 16.875 3.58001 16.7842C3.42321 16.7043 3.29572 16.5768 3.21583 16.42C3.125 16.2417 3.125 16.0084 3.125 15.5417V9.79167Z"
        stroke={stroke}
        stroke-opacity="0.7"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4583 3.125H15.5417C16.0084 3.125 16.2417 3.125 16.42 3.21583C16.5768 3.29572 16.7043 3.42321 16.7842 3.58001C16.875 3.75827 16.875 3.99162 16.875 4.45833V10.2083H11.8583C11.7183 10.2083 11.6483 10.2083 11.5948 10.1811C11.5478 10.1571 11.5095 10.1189 11.4856 10.0718C11.4583 10.0184 11.4583 9.94835 11.4583 9.80833V3.125Z"
        stroke={stroke}
        stroke-opacity="0.7"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
}
