import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Burn({
  viewBox = "0 0 24 24",
  active,
  color = active ? Colors.Active : Colors.BaseGrey,
  ...iconProps
}: IconProps) {
  return (
    <Icon size={24} {...iconProps} viewBox={viewBox}>
      <path
        fill="#B3B2B8"
        d="M16.5 4.5C15 6 14.4085 7.5 13.4085 8C12.4488 6.68234 11.0677 4.80176 10 2C6.56773 5.5 4 11.3828 4 13.9C4 18.3754 7.52266 22 11.875 22C16.2273 22 20 18.509 20 14.0336C20 11 19.5 8 16.5 4.5ZM15 18C14.2371 18.5309 13.016 19 12 19C9.46418 19 7 17.2236 7 14.5C7 13.1419 8 10.5 10 8C11.5 10.5 13.4085 14.0336 13.4085 14.0336L15.5 11C15.645 11.2373 16.3638 12.3189 16.5 13C17 15.5 16.5 16.9562 15 18Z"
      ></path>
      <path
        fill={active ? color : "#3C3B44"}
        d="M12 19C13.016 19 14.2371 18.5309 15 18C16.5 16.9562 17 15.5 16.5 13C16.379 12.3948 15.7979 11.4733 15.5682 11.109C15.5394 11.0634 15.5162 11.0264 15.5 11L13.4085 14.0336C13.4085 14.0336 11.5 10.5 10 8C8 10.5 7 13.1419 7 14.5C7 17.2236 9.46418 19 12 19Z"
      ></path>
    </Icon>
  );
}
