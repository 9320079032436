import React from "react";
import Icon, { IconProps } from "./Icon";

export default function SendFeedback({ viewBox = "0 0 12 12", ...iconProps }: IconProps) {
  return (
    <Icon size={12} fill="none" {...iconProps} viewBox={viewBox}>
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeOpacity="0.5"
        d="M1.25 2.75a1 1 0 011-1h7.5a1 1 0 011 1v5.5a1 1 0 01-1 1H7.688a.5.5 0 00-.319.115l-1.05.87a.5.5 0 01-.64-.003l-1.032-.865a.5.5 0 00-.321-.117H2.25a1 1 0 01-1-1v-5.5z"
      ></path>
      <path
        fill="#121718"
        stroke="#121718"
        strokeLinecap="square"
        strokeWidth="0.25"
        d="M3.375 5.5a.375.375 0 10.75 0 .375.375 0 00-.75 0zm2.25 0a.375.375 0 10.75 0 .375.375 0 00-.75 0zm2.25 0a.375.375 0 10.75 0 .375.375 0 00-.75 0z"
      ></path>
    </Icon>
  );
}
