import React from "react";
import Icon, { IconProps } from "./Icon";

export default function BookOpen({
  viewBox = "0 0 12 12",
  color = "#D3A5F7",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps} fill="transparent">
      <g clipPath="url(#clip0_1047_8448)">
        <g fill={color}>
          <path d="M5.25 1.875l-4.2-1.8C.825-.075.525 0 .3.15.15.3 0 .525 0 .75V9c0 .3.15.6.45.675l4.8 2.1v-9.9z"></path>
          <path d="M11.7.15c-.225-.15-.45-.15-.675-.075l-4.275 1.8v9.9l4.8-2.025c.3-.15.45-.375.45-.675V.75c0-.225-.15-.45-.3-.6z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1047_8448">
          <path fill="#fff" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}
