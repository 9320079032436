import React from "react";
import { IconProps } from "./Icon";

export default function SpinnerStatic({ width = 24, height = 24 }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="11.5" stroke="#fff" opacity="0.2"></circle>
      <path stroke="#fff" d="M23.5 12A11.5 11.5 0 113.868 3.868"></path>
    </svg>
  );
}
