import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export function NavInbox({
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  active,
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} width={width} height={height} {...iconProps} fill="none" stroke="none">
      <path
        fill={active ? colors.greenalpha : colors.gray200}
        d="M21.688 11.582l-3.646-5.486c-.278-.417-.903-.764-1.39-.764h-9.34c-.486 0-1.11.347-1.388.764l-3.646 5.486c-.174.243-.278.66-.278.938v4.479c0 .937.73 1.666 1.667 1.666h16.666c.903 0 1.667-.729 1.667-1.666v-4.48c-.035-.277-.139-.694-.313-.937zm-6.632.417l-1.112 2.222h-3.888l-1.112-2.222H4.64l2.986-4.445h8.715L19.326 12h-4.27z"
      />
      <path
        fill={colors.gray600}
        d="M15.056 12h4.27L16.34 7.554H7.625l-2.986 4.444h4.305l1.112 2.222h3.889L15.054 12z"
      />
    </Icon>
  );
}
