import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ThumbUp({
  color = "currentColor",
  stroke = color,
  viewBox = "0 0 14 14",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9165 12.8332C1.95001 12.8332 1.1665 12.0497 1.1665 11.0832V7.58317C1.1665 6.61667 1.95001 5.83317 2.9165 5.83317H3.72209L5.5723 2.13388C5.85129 1.57588 6.40447 1.21132 7.02121 1.17036L7.13754 1.1665H7.2915C8.0647 1.1665 8.69736 1.76823 8.74674 2.52895L8.74984 2.62484V5.83317H11.0822C11.1974 5.83317 11.3124 5.84455 11.4254 5.86715C12.3392 6.04993 12.9434 6.91372 12.8154 7.82496L12.7982 7.92637L12.0982 11.4264C11.9414 12.2103 11.2752 12.7837 10.4858 12.8301L10.3822 12.8332H2.9165ZM3.49984 6.99984H2.9165C2.59434 6.99984 2.33317 7.261 2.33317 7.58317V11.0832C2.33317 11.4053 2.59434 11.6665 2.9165 11.6665H3.49984V6.99984ZM7.2915 2.33317H7.13754C6.94114 2.33317 6.75973 2.43181 6.65244 2.59252L6.61579 2.65563L4.72809 6.43104C4.69771 6.49179 4.67826 6.55722 4.67043 6.62434L4.6665 6.69192V11.6665H10.3822C10.637 11.6665 10.8595 11.5016 10.9368 11.2641L10.9542 11.1976L11.6542 7.69757C11.7173 7.38166 11.5125 7.07435 11.1966 7.01117L11.1396 7.00268L11.0822 6.99984H8.74984C8.13479 6.99984 7.63091 6.52391 7.58637 5.92024L7.58317 5.83317V2.62484C7.58317 2.48165 7.47999 2.36257 7.34393 2.33787L7.2915 2.33317Z"
        fill={stroke}
      />
    </Icon>
  );
}
