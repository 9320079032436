import React from "react";
import Icon, { IconProps } from "./Icon";

export default function CustomerMenuIcon({ viewBox = "0 0 14 12", ...iconProps }: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path fillRule="evenodd" stroke="#B3B2B8" d="M6 9c-3 0-5 2-5 3l-1-1c1-1 3-3 6-3s5 2 6 3l-1 1c0-1-2-3-5-3ZM6 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
    </Icon>
  );
}

