import React from "react";
import Icon, { IconProps } from "./Icon";

export default function GoTo({
  viewBox = "0 0 14 14",
  color = "currentColor",
  fill = color,
  width = 14,
  height = 14,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.656 1.75c0-.362.294-.656.657-.656h2.333a1.823 1.823 0 011.823 1.823v8.166a1.823 1.823 0 01-1.823 1.823H8.312a.656.656 0 110-1.312h2.334a.51.51 0 00.51-.51V2.916a.51.51 0 00-.51-.51H8.312a.656.656 0 01-.656-.657z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.297 3.618a.656.656 0 10-.928.928l1.797 1.796H2.187a.656.656 0 000 1.313h4.979L5.369 9.451a.656.656 0 10.928.928L9.21 7.467a.66.66 0 00.15-.714.654.654 0 00-.145-.218L6.297 3.618z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
