import React from "react";
import Icon, { IconProps } from "./Icon";

export default function LinkExternal({
  viewBox = "0 0 14 14",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8.286a.857.857 0 0 0-1.714 0V10a.286.286 0 0 1-.286.286H2A.286.286 0 0 1 1.714 10V2c0-.158.128-.286.286-.286h1.714a.857.857 0 0 0 0-1.714H2Z"
      />
      <path
        fill={fill}
        d="M7.714 0H12v4.286a.857.857 0 0 1-1.714 0v-1.36L6.035 7.178a.857.857 0 1 1-1.213-1.213l4.252-4.25h-1.36a.857.857 0 0 1 0-1.715Z"
      />
    </Icon>
  );
}
