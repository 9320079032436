import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Dollar({
  active,
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M22.3469 5.09067C21.0063 4.54067 19.6656 4.30005 18.325 4.30005C14.0969 4.30005 9.86875 6.46567 5.64063 6.46567C4.575 6.46567 3.50938 6.32817 2.44375 5.98442C2.34063 5.95005 2.20313 5.95005 2.1 5.91567C1.51563 5.91567 1 6.39692 1 7.01567V17.9469C1 18.3594 1.24063 18.7719 1.61875 18.9438C2.95938 19.4938 4.3 19.7 5.64063 19.7C9.86875 19.7 14.0969 17.5688 18.325 17.5688C19.3906 17.5688 20.4563 17.7063 21.5219 18.05C21.625 18.0844 21.7625 18.0844 21.8656 18.0844C22.4844 18.0844 23 17.6375 23 17.0188V6.08755C23 5.67505 22.725 5.26255 22.3469 5.09067ZM11.5875 17.0532C9.49063 17.5688 7.53125 18.05 5.64063 18.05H5.60625C5.29688 18.05 4.74688 18.05 4.4375 17.9813C4.09375 17.3282 3.30313 16.5375 2.65 16.2282V9.4563C3.2 9.1813 3.8875 8.56255 4.23125 8.04692C4.60938 8.0813 5.22813 8.11567 5.60625 8.11567H5.64063C7.94375 8.11567 10.2125 7.5313 12.3781 6.9813C14.475 6.46567 16.4344 5.95005 18.325 5.95005H18.3594C18.6688 5.95005 19.2188 5.98442 19.5281 6.05317C19.8719 6.7063 20.6625 7.49692 21.35 7.8063V14.5782C20.7656 14.8532 20.0781 15.4719 19.7344 15.9875C19.3563 15.9532 18.7375 15.9188 18.3594 15.9188H18.325C16.0219 15.9188 13.7531 16.5032 11.5875 17.0532Z"
        fill={active ? Colors.Active : "#B3B2B8"}
      />
      <path
        d="M19.7344 15.9876C20.0781 15.4719 20.7656 14.8532 21.35 14.5782V7.80632C20.6625 7.49695 19.8719 6.70632 19.5281 6.0532C19.2187 5.98445 18.6687 5.95007 18.3594 5.95007H18.325C16.4344 5.95007 14.475 6.4657 12.3781 6.98132C10.2125 7.53132 7.94373 8.1157 5.6406 8.1157H5.60623C5.2281 8.1157 4.60935 8.08132 4.23123 8.04695C3.88748 8.56257 3.19998 9.18132 2.6156 9.45632V16.2282C3.3031 16.5376 4.09373 17.3282 4.43748 17.9813C4.74685 18.0501 5.29685 18.0501 5.60623 18.0501H5.6406C7.53123 18.0501 9.4906 17.5688 11.5875 17.0532C13.7531 16.5032 16.0219 15.9188 18.325 15.9188H18.3594C18.7375 15.9188 19.3562 15.9532 19.7344 15.9876ZM12 15.3001C10.4531 15.3001 9.24998 13.8219 9.24998 12.0001C9.24998 10.1782 10.4531 8.70007 12 8.70007C13.5125 8.70007 14.75 10.1782 14.75 12.0001C14.75 13.8219 13.5125 15.3001 12 15.3001Z"
        fill={"#77767E"}
      />
    </Icon>
  );
}
