import React from "react";
import Icon, { IconProps } from "./Icon";

export default function RDTaxCreditsService({
  viewBox = "0 0 60 60",
  width = 60,
  height = 60,
  fill = "none",
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox}>
      <rect
        width="59"
        height="59"
        x="0.5"
        y="0.5"
        fill="#1E54B7"
        fillOpacity="0.4"
        rx="29.5"
      ></rect>
      <rect width="59" height="59" x="0.5" y="0.5" stroke="#5B9EFF" rx="29.5"></rect>
      <path
        fill="#A5E6FF"
        d="M17.238 35V24.818h3.818c.782 0 1.438.136 1.969.408.533.272.936.653 1.208 1.143.275.488.412 1.056.412 1.706 0 .652-.139 1.22-.417 1.7-.275.477-.681.847-1.218 1.109-.537.258-1.197.387-1.979.387h-2.72v-1.53h2.471c.458 0 .832-.064 1.124-.19.292-.13.507-.316.646-.562.143-.248.214-.553.214-.914 0-.362-.071-.67-.214-.925a1.36 1.36 0 00-.651-.587c-.292-.136-.668-.204-1.128-.204h-1.69V35h-1.845zm5.26-4.614L25.018 35H22.96l-2.476-4.614h2.014zm6.808 4.763c-.696 0-1.294-.126-1.794-.378-.501-.252-.887-.591-1.159-1.019a2.697 2.697 0 01-.403-1.457c0-.407.083-.769.249-1.083.169-.315.401-.607.696-.875a9.602 9.602 0 011.034-.8l1.805-1.229c.265-.172.465-.354.601-.547.14-.192.21-.417.21-.676 0-.219-.094-.426-.28-.621-.182-.196-.434-.293-.755-.293a1.086 1.086 0 00-.955.547c-.089.162-.134.334-.134.516 0 .222.062.448.184.676.126.23.289.466.487.711l.647.781L34.516 35h-1.948L28.6 30.456c-.295-.345-.58-.684-.855-1.02a5.41 5.41 0 01-.671-1.043 2.733 2.733 0 01-.259-1.184c0-.48.111-.91.333-1.287.223-.381.532-.681.93-.9.401-.219.87-.328 1.407-.328s.998.106 1.382.318c.385.212.681.494.89.845.209.352.313.734.313 1.149 0 .48-.119.911-.358 1.292a3.78 3.78 0 01-.994 1.03l-2.058 1.506c-.338.245-.58.494-.726.745-.146.25-.219.461-.219.637 0 .268.068.512.204.73.136.22.327.395.572.528.248.129.538.194.87.194.364 0 .724-.082 1.079-.244a3.23 3.23 0 00.97-.706 3.43 3.43 0 00.695-1.094 3.77 3.77 0 00.259-1.422h1.546c0 .643-.071 1.203-.214 1.68a4.304 4.304 0 01-.552 1.204 3.512 3.512 0 01-.716.78c-.08.057-.155.113-.228.17-.073.056-.15.112-.229.169a3.357 3.357 0 01-1.258.715 4.61 4.61 0 01-1.407.23zm9.77-.149h-3.45V24.818h3.52c1.011 0 1.88.204 2.605.612.73.404 1.29.986 1.68 1.745.392.759.587 1.667.587 2.724 0 1.06-.197 1.972-.591 2.734a4.089 4.089 0 01-1.696 1.755c-.735.408-1.62.612-2.654.612zm-1.605-1.596h1.516c.71 0 1.301-.13 1.775-.388.474-.261.83-.65 1.069-1.168.238-.52.358-1.17.358-1.949 0-.779-.12-1.425-.358-1.939a2.441 2.441 0 00-1.06-1.158c-.463-.259-1.04-.388-1.73-.388h-1.57v6.99z"
      ></path>
    </Icon>
  );
}
