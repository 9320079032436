import React from "react";
import { colors } from "@puzzle/theme";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Plug({
  active,
  viewBox = "0 0 14 20",
  width = 14,
  height = 20,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M13.4167 6.49994H0.583333C0.255208 6.49994 0 6.79161 0 7.08327V8.24994C0 8.57806 0.255208 8.83327 0.583333 8.83327H1.16667L1.16668 9.83331C1.16668 12.4948 3.2448 15.0469 5.83334 15.5573V19.9999H8.16668V15.5573C10.7188 15.0469 12.7969 12.4948 12.8333 9.83331L12.8333 8.83327H13.4167C13.7083 8.83327 14 8.57806 14 8.24994V7.08327C14 6.79161 13.7083 6.49994 13.4167 6.49994Z"
        fill={active ? Colors.ActiveGrey : colors.gray500}
      />
      <path
        d="M11.6666 1.16667C11.6666 0.546875 11.1197 0 10.4999 0C9.84367 0 9.33325 0.546875 9.33325 1.16667L9.33325 5H11.6666V1.16667ZM4.66659 1.16667C4.66659 0.546875 4.11971 0 3.49992 0C2.84367 0 2.33325 0.546875 2.33325 1.16667V5H4.66659V1.16667Z"
        fill={active ? Colors.ActiveGrey : colors.gray500}
      />
      <path
        d="M10.4999 0C11.1197 0 11.6666 0.546875 11.6666 1.16667V5H9.33325L9.33325 1.16667C9.33325 0.546875 9.84367 0 10.4999 0Z"
        fill={active ? Colors.Active : Colors.BaseGrey}
      />
      <path
        d="M3.49992 0C4.11971 0 4.66659 0.546875 4.66659 1.16667V5H2.33325V1.16667C2.33325 0.546875 2.84367 0 3.49992 0Z"
        fill={active ? Colors.Active : Colors.BaseGrey}
      />
    </Icon>
  );
}
