import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Headset({ size = 20, ...iconProps }: IconProps) {
  // This icon isn't resizing properly no matter what I do with the viewbox :(
  const viewBox = `0 0 ${size} ${size}`;

  return (
    <Icon width={size} height={size} {...iconProps} fill="none" viewBox={viewBox}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox={viewBox}
      >
        <g>
          <g>
            <g>
              <path
                fill="#fff"
                fillOpacity="0.7"
                d="M15 7.5a4.5 4.5 0 10-9 0h1.507a2.993 2.993 0 015.986 0H15z"
              ></path>
              <path
                fill="#fff"
                fillOpacity="0.7"
                d="M16 14.5a4.501 4.501 0 01-4.5 4.5v-1.507a2.994 2.994 0 002.993-2.993H16z"
              ></path>
              <path
                stroke="#fff"
                strokeOpacity="0.7"
                strokeWidth="1.5"
                d="M3.25 8.25H7.75V13.75H3.25z"
              ></path>
              <path
                stroke="#fff"
                strokeOpacity="0.7"
                strokeWidth="1.5"
                d="M13.25 8.25H17.75V13.75H13.25z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
}
