import React from "react";

export default function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="#121718"
        strokeLinecap="square"
        strokeWidth="2"
        d="M10 6.667H6m2 2.666H6m-4 4h8.667A3.333 3.333 0 0014 10V6a3.333 3.333 0 00-3.333-3.333H5.333A3.333 3.333 0 002 6v7.333z"
      ></path>
    </svg>
  );
}
