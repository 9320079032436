import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ThumbDown({
  color = "currentColor",
  stroke = color,
  viewBox = "0 0 14 14",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0825 1.16675C12.049 1.16675 12.8325 1.95025 12.8325 2.91675V6.41675C12.8325 7.38325 12.049 8.16675 11.0825 8.16675H10.2769L8.4267 11.866C8.1477 12.424 7.59452 12.7886 6.97778 12.8296L6.86145 12.8334H6.70749C5.93429 12.8334 5.30164 12.2317 5.25226 11.471L5.24916 11.3751V8.16675H2.91684C2.8016 8.16675 2.68664 8.15536 2.57364 8.13276C1.65976 7.94999 1.05561 7.08619 1.18357 6.17496L1.20083 6.07354L1.90083 2.57354C2.05761 1.78964 2.72376 1.21621 3.5132 1.16979L3.61684 1.16675H11.0825ZM10.4992 7.00008H11.0825C11.4047 7.00008 11.6658 6.73891 11.6658 6.41675V2.91675C11.6658 2.59458 11.4047 2.33341 11.0825 2.33341H10.4992V7.00008ZM6.70749 11.6667H6.86145C7.05785 11.6667 7.23927 11.5681 7.34656 11.4074L7.3832 11.3443L9.27091 7.56888C9.30128 7.50813 9.32074 7.4427 9.32856 7.37558L9.33249 7.308V2.33341H3.61684C3.36195 2.33341 3.13954 2.49833 3.06224 2.73584L3.04484 2.80235L2.34484 6.30235C2.28165 6.61826 2.48653 6.92557 2.80244 6.98875L2.85936 6.99724L2.91684 7.00008H5.24916C5.8642 7.00008 6.36809 7.47601 6.41263 8.07968L6.41583 8.16675V11.3751C6.41583 11.5183 6.519 11.6374 6.65506 11.662L6.70749 11.6667Z"
        fill={stroke}
      />
    </Icon>
  );
}
