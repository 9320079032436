import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Close({
  viewBox = "0 0 16 14",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.789 2.258c.116 0 .211-.095.211-.211v-.844a.211.211 0 0 0-.211-.21H.461a.211.211 0 0 0-.211.21v.844c0 .116.095.21.21.21H15.79zm0 5.625c.116 0 .211-.095.211-.211v-.844a.211.211 0 0 0-.211-.21H.461a.211.211 0 0 0-.211.21v.844c0 .116.095.21.21.21H15.79zm-5 5.625c.116 0 .211-.095.211-.211v-.844a.211.211 0 0 0-.211-.21H.461a.211.211 0 0 0-.211.21v.844c0 .116.095.21.21.21H10.79z"
      />
    </Icon>
  );
}
